@font-face {
font-family: '__fontsNext_8df7fb';
src: url(/_next/static/media/aa7e074902d3e5d8-s.p.ttf) format('truetype');
font-display: swap;
font-weight: bold;
}

@font-face {
font-family: '__fontsNext_8df7fb';
src: url(/_next/static/media/b4949cb48784e776-s.p.ttf) format('truetype');
font-display: swap;
font-weight: normal;
}

@font-face {
font-family: '__fontsNext_8df7fb';
src: url(/_next/static/media/f66b323890083e1f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
}@font-face {font-family: '__fontsNext_Fallback_8df7fb';src: local("Arial");ascent-override: 90.29%;descent-override: 37.78%;line-gap-override: 0.00%;size-adjust: 93.70%
}.__className_8df7fb {font-family: '__fontsNext_8df7fb', '__fontsNext_Fallback_8df7fb'
}.__variable_8df7fb {--fonts-next: '__fontsNext_8df7fb', '__fontsNext_Fallback_8df7fb'
}


  :root {
    direction: rtl; 
  }

  body {
    font-family: var(--fonts-next);
    scrollbar-color: "#E5E5E5 white";
    scrollbar-width: "thin";
  }

  h1, h2, h3, h4 {
    position: relative;
    font-family: var(--fonts-next);
  }

  svg {
    height: auto;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 1024px;
  }

  img {
    background-size: cover;
  }

  ul {
    list-style: none;
  }

  #mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
