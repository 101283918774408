
  :root {
    direction: rtl; 
  }

  body {
    font-family: var(--fonts-next);
    scrollbar-color: "#E5E5E5 white";
    scrollbar-width: "thin";
  }

  h1, h2, h3, h4 {
    position: relative;
    font-family: var(--fonts-next);
  }

  svg {
    height: auto;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 1024px;
  }

  img {
    background-size: cover;
  }

  ul {
    list-style: none;
  }

  #mainContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  